import React, { useCallback } from 'react';
import styled from 'styled-components';
import { getYoutubeId } from '../../utils/getYoutubeId';
import cardBg from '../../images/video_card_bg.png';
import Img from '../../utils/OptimizedImage';
import Icon from '../../styles/atoms/icons';

const StoryVideoCard = ({
  isHidden,
  isCurrent,
  story,
  setVideo,
  setPlay,
  setCTA,
  setModalCTAContent,
  transform,
  goSlider,
}) => {
  const youtubeId = getYoutubeId(story?.websiteVideo[0]?.youtubeUrl);
  const imageUrl = `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;

  const playVideo = useCallback(() => {
    setVideo(youtubeId);
    if (story.websiteVideo[0].callToAction) {
      setCTA(true);
      setModalCTAContent(story.websiteVideo[0]);
    } else {
      setCTA(false);
      setModalCTAContent([]);
    }
    setPlay(true);
  }, [
    setCTA,
    setModalCTAContent,
    setPlay,
    setVideo,
    story.websiteVideo,
    youtubeId,
  ]);

  return (
    <Card
      isHidden={isHidden}
      isCurrent={isCurrent}
      backgroundColor={story?.industry?.hex}
      transform={transform}
      onClick={goSlider}
      className="story-card"
    >
      <div className="card-image">
        {story?.websiteVideo[0]?.videoThumbnail?.image ? (
          story?.websiteVideo[0]?.videoThumbnail?.image?.gatsbyImageData ? (
            <Img
              image={
                story?.websiteVideo[0]?.videoThumbnail?.image?.gatsbyImageData
              }
              alt={story?.websiteVideo[0]?.videoThumbnail?.internalName}
              title={story?.websiteVideo[0]?.videoThumbnail?.image?.title}
            />
          ) : (
            <Img
              src={story?.websiteVideo[0]?.videoThumbnail?.image?.url}
              alt={story?.websiteVideo[0]?.videoThumbnail?.internalName}
              title={story?.websiteVideo[0]?.videoThumbnail?.image?.title}
            />
          )
        ) : (
          <img src={imageUrl} alt="Video Cover" />
        )}
      </div>
      <div className="overlay">
        <div className="watch-video-btn">
          <span
            onClick={() => playVideo()}
            role="button"
            tabIndex={0}
            aria-label="Play video button"
          >
            <Icon id="FaPlay" />
          </span>
        </div>
      </div>
    </Card>
  );
};

export default StoryVideoCard;

export const Card = styled.div`
  position: relative;
  width: calc(50% - 30px);
  @media (max-width: 991.98px) {
    width: 100%;
  }
  left: ${props => props.transform}px;
  border-radius: 10px;
  margin-right: 30px;
  display: inline-block;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  opacity: ${props => (props.isHidden ? 0 : 1)};
  transform: ${props =>
    props.isHidden
      ? 'perspective(1000px)rotateY(-90deg)'
      : 'perspective(1000px)rotateY(0deg)'};
  transform-origin: right;
  cursor: pointer;

  &::before {
    content: '';
    display: flex;
    transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
    transition-delay: ${props => (props.isCurrent ? '0' : '0.1s')};
    padding-top: ${props => (props.isCurrent || props.isHidden ? 160 : 119)}%;
    @media (max-width: 991.98px) {
      padding-top: ${props => (props.isCurrent || props.isHidden ? 140 : 119)}%;
    }
    @media (max-width: 575.98px) {
      padding-top: 100%;
    }
  }

  .card-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;

    .gatsby-image-wrapper {
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 10px;
    }
  }

  .overlay {
    position: absolute;
    color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 56px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    white-space: normal;
    @media (max-width: 767.9px) {
      padding: 30px;
    }
    @media (max-width: 480px) {
      padding: 20px;
    }
    background: ${props =>
      props.isCurrent
        ? 'transparent'
        : 'linear-gradient(60.84deg, rgba(0, 0, 0, 0.7) 24.47%, rgba(0, 0, 0, 0.049) 67.94%)'};
    border-radius: 10px;
    overflow: hidden;

    .watch-video-btn {
      span {
        cursor: pointer;
        display: inline-block;
        width: 100%;
        height: 100%;
        text-align: center;
        line-height: 72px;
        padding-left: 4px;
      }

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 72px;
      height: 72px;
      border-radius: 50%;
      background-color: var(--primary-color);

      svg {
        font-size: 22px;
      }
    }
  }
`;

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 48px;
  background-image: url(${cardBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;

  &:hover {
    box-shadow: 0px 8px 80px rgba(106, 114, 158, 0.2);
  }

  h6 {
    color: #fff;
    margin-bottom: 24px;
  }

  h3 {
    margin-bottom: 24px;
    white-space: break-spaces;
    color: #fff;
  }

  .btn-outline {
    border-color: #fff;
    color: var(--primary-color);
    background-color: #fff;
  }

  @media (max-width: 575.98px) {
    padding-left: 15px;
    padding-right: 15px;
    h6 {
      font-size: 14px;
    }

    h3 {
      font-size: 18px;
      line-height: 1.5;
    }

    button {
      font-size: 12px;
      padding: 10px 15px;
    }
  }
`;
